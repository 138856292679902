import React from "react";
import styled from "styled-components";


// import Expertise from './../../components/homeSections/Expertise';
// import Projects from './../../components/homeSections/Projects';
// import HowWeWork from './../../components/homeSections/howWeWork';
// import Locations from './../../components/homeSections/locations';
// import LearningHub from './../../components/homeSections/learningHub';
// import Team from './../../components/homeSections/team';
// import JoinOurStory from '../../components/homeSections/joinOurStory'
// import HomeHeader from "../../components/layout/HomeHeader";
import HeaderTemp from "../../components/layout/HeaderTemp";
// import UnderConstruction from "../../components/UnderConstruction";
import { useTranslation } from 'react-i18next';

  

export default () => {

  const { t } = useTranslation();
  

  return (
    <>
      {/*<HomeHeader*/}
      {/*  gif={'home.png'}*/}
      {/*  title={t("title")}*/}
      {/*  description={t("description")}*/}
      {/*/>*/}
      <HeaderTemp
        title={t("titleAlt")}
        description={t("descriptionAlt")}
      />
      <Wrapper>
        {/*<UnderConstruction/>*/}
        {/*<Expertise/>*/}
        {/*<Projects/>*/}
        {/*<HowWeWork/>*/}
        {/*<Locations/>*/}
        {/*<LearningHub/>*/}
        {/*<JoinOurStory/>*/}
        {/*<Team/>*/}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
`

// const Body = styled.div`
// 	margin: 0 auto;
// 	max-width: 1400px;
// 	width: calc(100% - 100px);
//   @media (max-width: 500px) {
// 		width: calc(100%);
//   }
// `;