import styled from 'styled-components'
import React from "react";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';




const Footer = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper id="signup">
      <InnerWrapper>
        <InnerBodyWrapper>
            <ContentWrapper>
                <TitleWrapper>
                  {t("footer.title1")}
                </TitleWrapper>
                <form action="/thank-you" name="email" method="post" >
                  <input type="hidden" name="form-name" value="email" />
                <MailWrapper>
                  <Label>
                      <YourMailTextWrapper
                          type={'email'}
                          name={'email'}
                          placeholder={t("footer.YourMail")}
                      />
                  </Label>
                      <Button type={'submit'}>
                          <ArrowImg
                            src={'../../../assets/images/arrow.svg'}
                            alt={'arrow'}
                          />
                      </Button>
                </MailWrapper>
                </form>
                <TextWrapper1>
                  {t("footer.Consent")}
                </TextWrapper1>
                <Link to={"privacy"} onClick={()=>window.scrollTo(0,0)}>
                    <PrivacyPolicyWrapper >
                      {t("footer.privacy")}
                    </PrivacyPolicyWrapper>
                </Link>
            </ContentWrapper>
            <LocationsWrapper>
                <LocationWrapper>
                    <Location>
                        London
                    </Location>
                        <DetailsWrapper>
                            <TextWrapper2>
                                Kemp house, 160 City Rd
                            </TextWrapper2>
                            <TextWrapper2>
                                London EC1V 2NX
                            </TextWrapper2>
                            <TextWrapper2>
                                +4474 9776 9333
                            </TextWrapper2>
                        </DetailsWrapper>
                </LocationWrapper>
                <LocationWrapper>
                    <Location>
                        Berlin
                    </Location>
                        <DetailsWrapper>
                            <TextWrapper2>
                                62 Hohenzollerndamm
                            </TextWrapper2>
                            <TextWrapper2>
                                DE-14199, Berlin
                            </TextWrapper2>
                            <TextWrapper2>
                                +4930 2339 7270
                            </TextWrapper2>
                        </DetailsWrapper>
                </LocationWrapper>
                <LocationWrapper>
                    <Location>
                        Ljubljana
                    </Location>
                        <DetailsWrapper>
                            <TextWrapper2>
                                Kotnikova ulica 28
                            </TextWrapper2>
                            <TextWrapper2>
                                SI-1000, Ljubljana
                            </TextWrapper2>
                            <TextWrapper2>
                                +386 40 719 147
                            </TextWrapper2>
                        </DetailsWrapper>
                </LocationWrapper>
            </LocationsWrapper>
        </InnerBodyWrapper>
        <InnerFooterWrapper>
            <SocialWrapper>
                <A href="https://www.linkedin.com/company/camac-spaceslovenija">
                    <SocialImg
                        src={'../../../assets/images/linkedin.svg'}
                        alt={'Linked in'}
                    />
                </A>
                <A href="https://www.youtube.com/@camacspace">
                    <SocialImg
                        src={'../../../assets/images/youtube.svg'}
                        alt={'YouTube'}
                    />
                </A>
                <A href="https://www.instagram.com/camacspace/">
                    <SocialImg
                        src={'../../../assets/images/instagram.svg'}
                        alt={'Instagram'}
                    />
                </A>
            </SocialWrapper>
            <Link to={"/"} onClick={()=>window.scrollTo(0,0)}>
                <LogoWrapper>
                    <Logo
                        src={'../../../assets/images/Logo2.svg'}
                        alt={'Logo'}
                    />
                </LogoWrapper>
            </Link>
        </InnerFooterWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

export default Footer

const LogoWrapper = styled.div`
    display: none;
    @media (min-width: 600px) {
        display: block;
        margin-top: 124px;
        margin-right: 48px;
    }
`;

const DetailsWrapper = styled.div`
    min-width: 192px;
    @media (min-width: 600px) {
        margin-top: 12px;    
    }
`;
const Logo = styled.img``;
const A = styled.a``;

const TitleWrapper = styled.h1`
    font-size: 24px;
    font-weight: bold;
    font-family: StudioFeixenSansVariable;
    line-height: 36px;
    max-width: 600px;
    letter-spacing: -0.1px;
    @media (min-width: 600px) {
        line-height: 133%;
        letter-spacing: -0.17px;
        font-weight: normal;
        font-size: 3.3vw;
    }
    @media (min-width: 1440px) {
        font-size: 48px;
    }
`;

const MailWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #FFFFFF;
    margin-top: 80px;
    @media (min-width: 600px) {
        margin-top: 64px;
    }
`;

const ContentWrapper = styled.div`
    @media (min-width: 600px) {
        width: 50%;
    }
`;

const LocationsWrapper = styled.div`
    @media (min-width: 600px) {
        margin-right: 20px;
        align-content: flex-start;
    }
`;

const InnerBodyWrapper = styled.div`
    @media (min-width: 600px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`;

const InnerFooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    @media (min-width: 600px) {
        display: flex;
        justify-content: space-between;
    }
`;

const YourMailTextWrapper = styled.input`
    font-size: 24px;
    height: 36px;
    color: #FFFFFF;
    font-weight: bold;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: StudioFeixenSansVariable;
    line-height: 36px;
    letter-spacing: -0.1px;
    @media (min-width: 600px) {
        font-weight: bold;
        font-size: 2.5vw;
        width: 100%;
        line-height: 98%;
        letter-spacing: -0.13px;
    }
     @media (min-width: 1440px) {
        font-size: 32px;
    }
`;

const Label = styled.label`
    width: 90%;
`;

const Button = styled.button`
    border: none;
    outline: none;
    background: #000000;
`;

const ArrowImg = styled.img`

`;

const TextWrapper1 = styled.p`
    font-family: StudioFeixenSansVariable;
    font-size: 16px;
    font-weight: bold;
    margin-top: 21px;
    line-height: 24px;
    letter-spacing: -0.2px;
`;

const PrivacyPolicyWrapper = styled.div`
    font-size: 16px;
    font-weight: bold;
    font-family: StudioFeixenSansVariable;
    margin-top: 48px;
    color: #FFFFFF;
    text-decoration: underline #FFFFFF;
    line-height: 32px;
    letter-spacing: -0.07px;
     @media (min-width: 600px) {
        margin-top: 64px;
    }
`;

const LocationWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 32px;
    border-top: 1px solid #DBDAD9;
    @media (min-width: 600px) {
        margin: 20px 0 40px 0;
        flex-direction: column;
        border-top: none;
    }
`;



const Location = styled.p`
    font-family: StudioFeixenSansVariable;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: -0.3px;
    
`;

const TextWrapper2 = styled.p`
    font-family: StudioFeixenSansVariable;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: white;
    text-decoration: none;
`;


const Wrapper = styled.footer`
    display: flex;
    justify-content: center; 
    width: 100%;
    background: #000000;
    color: #FFFFFF;
    min-height: 814px;
    @media (min-width: 600px) {
       min-height: 814px;
    }
`;

const InnerWrapper = styled.div`
    max-width: 1440px;
    width: calc(100% - 80px);
    margin: 120px auto;
    @media (max-width: 600px) {
        width: calc(100% - 40px);
    }
`;

const SocialWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
    width: 240px;
     @media (min-width: 600px) {
        width: 288px;
        align-self: flex-end;
        margin-bottom: 5px;
    }
`;

const SocialImg = styled.img``;
