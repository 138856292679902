import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import React from 'react';

//
// import Contact from "./pages/contact";
// import WebFormSuccessHeader from "./webForm/WebFormSuccessHeader";
// import WebPrivacyPage from "./webPrivacy/WebPrivacyPage";
// import LandingPage from './landing/LandingPage';

import home from './pages/home';
import ScrollToTop from "./components/reusable/ScrollToTop";
// import Brochures from './pages/brochures';
// import HowWeWork from './pages/HowWeWork';
// import WhatWeBelieve from './pages/WhatWeBelieve';
// import Project from './pages/project';



const Routes = () => {

    return (
      <ScrollToTop>
        <Switch >
          <Route exact path="/" component={home}/>
          <Route exact path="/home" component={home}/>
          {/*<Route exact path="/brochures" component={Brochures} />*/}
          {/*<Route exact path="/contact" component={Contact} />*/}
          {/*<Route exact path="/what-we-believe" component={WhatWeBelieve} />*/}
          {/*<Route exact path="/how-we-work" component={HowWeWork} />*/}
          {/*<Route exact path="/project/control-risks" component={() => <Project project={"Control Risks"}/>} />*/}
          {/*<Route exact path="/project/dragons-dream" component={() => <Project project={"Dragons dream"}/>} />*/}
          {/*<Route exact path="/project/medicofit" component={() => <Project project={"MedicoFit"}/>} />*/}
          {/*<Route exact path="/project/office-space-of-today" component={() => <Project project={"ECM Partners"}/>} />*/}
          <Route path="/404" component={home} />
        </Switch>
      </ScrollToTop>
    );

 }

 export default Routes;