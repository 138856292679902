//nodemodule imports
import React from "react";
import styled from "styled-components"
import {Link} from "react-router-dom";

//project imports
import {TextP1, Title} from "../reusable/styles";
// import ChangeLocale from "../common/ChangeLocale";


export default ({title, description}) => {
  return(
    <Wrapper>
      <TopBar>
        <Link to={"/"} onClick={()=>window.scrollTo(0,0)}>
          <Logo src={'assets/images/logo.svg'} alt={'assets/images/headerImages/logo.svg'}/>
        </Link>
      </TopBar>
      <InnerWrapper>
        <Img
          src={'assets/images/assemble.svg'}
          alt={'assets/images/assemble.svg'}
        />
        <TitleWrapper>
          <Title theme={TitleTheme}>Website under renovation.</Title>
        </TitleWrapper>
        <DescriptionWrapper>
          <TextP1 theme={TextP1Theme}>For more information please check out our YouTube channel or visit our LinkedIn page.</TextP1>
        </DescriptionWrapper>
        <LinkWrapper>
          <Link2>
            <A href="https://www.linkedin.com/company/camac-spaceslovenija">
              <SocialImg
                src={'../../../assets/images/linkedinBlack.svg'}
                alt={'Linked in'}
              />
            </A>
            <TextP1>LinkedIn</TextP1>
          </Link2>
          <Link2>
            <A href="https://www.youtube.com/@camacspace">
              <SocialImg
                src={'../../../assets/images/youtubeBlack.svg'}
                alt={'YouTube'}
              />
            </A>
            <TextP1>YouTube</TextP1>
          </Link2>
        </LinkWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background: #FAFA00;
  margin: 0 auto;
  height: 100vh;
  min-height: 400px;
  @media (max-width: 900px) {
    max-height: 700px;
  }  
`

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  height: calc(100% - 100px);
  position: relative;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content:center;
  @media (max-width: 900px) {
    background-size: 150%;
  }  
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  max-height: 250px;
  max-width: 250px;
  @media (max-width: 900px) {
    max-height: 150px;
    max-width: 150px;
  }
`

const TitleWrapper = styled.div`
  max-width: 761px;
  margin: 40px 0 0 0;
  width: 100%;
`

const SocialImg = styled.img`
`;

const A = styled.a`
  max-height: 32px;
  max-width: 32px;
  margin: 0 10px 0 0;
`;


const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 0 0;
`

const Link2 = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
`


const DescriptionWrapper = styled.div`
  max-width: 700px;
  width: calc(100% - 40px);
  margin: 40px 0 0 0;
`

const TopBar = styled.div`
  width: calc(100% - 80px);
  margin: 0 auto;
  min-height: 100px;
  display: flex;
  align-items:center;
  justify-content: space-between;
  @media (max-width: 600px) {
    width: calc(100% - 40px);
  };
`
const Logo = styled.img`
  height: 32px;
  @media (max-width: 500px) {
    height: 26px;
  };
`

// const ButtonTheme = {
//   width: '128px',
//   height: '48px',
// }

const TitleTheme = {
  textAlign: 'center'
}

const TextP1Theme = {
  textAlign: 'center',
}
