import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import XHR from "i18next-xhr-backend";

import slovenian from "./locales/slovenian";
import english from "./locales/english";

i18n
  // .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng:"en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: '.', // we do not use keys in form messages.welcome


    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ","
    },

    // react: {
    //   useSuspense: false,
    //   wait: true
    // },

    resources: {
      en: {
        translations: english
      },
      si: {
        translations: slovenian
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;