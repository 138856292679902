import React from 'react';
import styled from 'styled-components';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import Routes from "./Routes";
import { I18nextProvider } from "react-i18next";

// import Link from 'react-router-dom/Link';
import Footer from "./components/layout/Footer";
// import Popup from "./Popup";
import localization from "./localization";


const App = ({children}) => {
  return (
    <I18nextProvider i18n={localization}>
      <BrowserRouter>
        <Wrapper>
          <Routes />
          <Footer />
          {/*<Popup />*/}
           {/*<div style={{display:"none"}}>
            <Link to="/terms-and-conditions"></Link>
            <Link to="/privacy"></Link>
            <Link to="/"></Link>
            <Link to="/contact"></Link>
            <Link to="/thank-you"></Link>
            <Link to="/contact-thank-you"></Link>
          </div>*/}
        </Wrapper>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;


const Wrapper = styled.main(props => ({

}));